// API
import BaseAPI from './base';

/**
 * Implement Customer API
 */
export default class CustomerAPI extends BaseAPI {
  constructor() {
    super('people');
  }
}
