<template>
  <Form @submit="save">
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbs" class="ma-0 pa-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>{{ this.uuid ? 'Editar' : 'Agregar' }} orden</h1>
      </v-col>

      <v-col cols="12" class="d-flex justify-center">
        <v-radio-group v-model="individualSelection" row :disabled="readOnly">
          <v-radio label="Cita para recolección" :value="false" />
          <v-radio label="Arma tu pedido" :value="true" />
        </v-radio-group>
      </v-col>

      <v-col v-if="cannot('customer')" cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.customer_id"
          :disabled="!!this.uuid"
          :items="customers"
          item-text="full_name"
          item-value="uuid"
          label="Cliente *"
          prepend-icon="mdi-account"
          rules="required"
        />
      </v-col>

      <v-col v-if="cannot('customer')" cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.employee_id"
          :items="employees"
          item-text="full_name"
          item-value="uuid"
          label="Empleado *"
          prepend-icon="mdi-account"
          rules="required"
        />
      </v-col>

      <v-col v-if="cannot('customer')" cols="12" sm="6" lg="4">
        <SelectField
          v-model="form.vehicle_id"
          :items="vehicles"
          item-text="name"
          item-value="uuid"
          label="Vehículo *"
          prepend-icon="mdi-car"
          rules="required"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.date"
          label="Fecha *"
          type="date"
          prepend-icon="mdi-calendar"
          rules="required"
          :disabled="readOnly"
          :min="currentDate"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.time_start"
          label="Hora Inicio *"
          type="time"
          prepend-icon="mdi-clock-outline"
          rules="required|schedule"
          :disabled="readOnly"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <TextField
          v-model="form.time_end"
          label="Hora Fin *"
          type="time"
          prepend-icon="mdi-clock-outline"
          rules="required|schedule"
          :disabled="readOnly"
        />
      </v-col>

      <v-col v-if="can('customer')" cols="12" sm="6" lg="4">
        <SelectField
          v-model="office"
          :items="offices"
          item-text="name"
          item-value="id"
          label="Sucursal *"
          prepend-icon="mdi-store"
          rules="required"
          return-object
          :disabled="readOnly"
        />
      </v-col>
    </v-row>

    <ProductSelection
      v-if="individualSelection"
      ref="productSelection"
      :items="form.items"
      :office="office"
      :paid-pickup="order.paid_pickup"
      :read-only="readOnly"
    />

    <v-row v-else>
      <v-col cols="12">
        <v-card>
          <v-card-text class="text-center">
            <b class="mr-1 text-h5">TOTAL: $ 35.00</b>
            <br />

            <small style="line-height: 1">
              Estos $35 pesos son a cuenta de tu nota.
              <br />
              En caso de cancelar la cita antes de 24 horas se tomarán para tu siguiente pedido.
              <br />
              En caso de hacer una visita en falso (no cancelar o cambiar la cita 24 hrs previo) se
              tomará como pena.
            </small>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!can('customer')" cols="12" class="text-center">
        <v-btn color="primary" type="submit" :disabled="disableSubmit">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-col>

      <v-col v-else cols="12" class="text-center">
        <v-btn color="success" :disabled="disableSubmit" @click="showPayment"> Pagar </v-btn>
      </v-col>
    </v-row>

    <Payment :show.sync="showPaymentModal" :amount="total" @save="save" />
  </Form>
</template>

<script>
// Libraries
import moment from 'moment';

// API
import CustomerAPI from '@/api/customer';
import EmployeeAPI from '@/api/employee';
import OfficeAPI from '@/api/office';
import OrderAPI from '@/api/order';
import VehicleAPI from '@/api/vehicle';

// Components
import Payment from '@/components/payment/index.vue';
import ProductSelection from '@/components/order/ProductSelection.vue';

export default {
  name: 'OrderForm',

  props: {
    uuid: {
      type: String,
      default: null,
    },
  },

  components: {
    Payment,
    ProductSelection,
  },

  data: () => ({
    form: {
      customer_id: null,
      date: null,
      employee_id: null,
      items: [],
      time_end: null,
      time_start: null,
      vehicle_id: null,
    },
    discount: {
      code: null,
    },
    currentDate: moment().format('YYYY-MM-DD'),
    customers: [],
    employees: [],
    individualSelection: false,
    office: {},
    order: {},
    offices: [],
    postalCode: null,
    readOnly: false,
    vehicles: [],

    // Payment modal
    showPaymentModal: false,
    total: 0,
  }),

  computed: {
    breadcrumbs() {
      const items = [
        {
          text: 'Órdenes',
          disabled: false,
          exact: true,
          to: { name: 'order-list' },
        },
      ];

      if (this.uuid) {
        const name = this.form.number || '';
        items.push(
          {
            text: name.toUpperCase(),
            disabled: false,
            exact: true,
            to: { name: 'order-detail', params: { uuid: this.uuid } },
          },
          {
            text: 'Editar',
            disabled: true,
          },
        );
      } else {
        items.push({
          text: 'Agregar',
          disabled: true,
        });
      }

      return items;
    },

    disableSubmit() {
      const collectionDateTime = this.form.date && this.form.time_end && this.form.time_start;
      const validateOffice = this.can('customer') ? !!this.office.uuid : true;

      return this.individualSelection
        ? !collectionDateTime || !this.form.items.length || !validateOffice
        : !collectionDateTime || !validateOffice;
    },
  },

  watch: {
    async individualSelection() {
      this.form.items = [];

      if (this.order.uuid) {
        this.form.items = this.getOrderItems(this.order);
      }
    },
  },

  async created() {
    this.api = new OrderAPI();
    this.customerAPI = new CustomerAPI();
    this.employeeAPI = new EmployeeAPI();
    this.officeAPI = new OfficeAPI();
    this.vehicleAPI = new VehicleAPI();

    if (this.can('customer')) {
      this.postalCode = this.$store.getters.profile.address?.postal_code;

      if (!this.postalCode) {
        this.$router.push({
          name: 'account',
          query: {
            redirect: this.$route.path,
          },
        });
        this.$store.dispatch('notification/notifyError', 'Por favor completa tu perfil.');
        return;
      }

      await this.getAvailableOffices();
    } else {
      await this.getCustomers();
      await this.getEmployees();
      await this.getVehicles();
    }

    if (this.uuid) {
      await this.detail();
    }
  },

  methods: {
    getOrderItems(order) {
      return order.products.map((product) => ({
        quantity: product.quantity,
        product: product.product,
      }));
    },

    async save() {
      this.$store.commit('showLoader');
      try {
        this.form.discount = this.individualSelection
          ? this.$refs.productSelection.getDiscount()
          : null;

        if (this.office.uuid) {
          this.form.office_uuid = this.office.uuid;
        }

        if (this.can('customer')) {
          this.form.customer_id = null;
          this.form.employee_id = null;

          if (this.individualSelection) {
            this.form.paid = true;
          } else {
            this.form.paid_pickup = true;
          }
        }

        if (this.uuid) {
          await this.api.update(this.uuid, this.form);
          this.$router.push({ name: 'order-detail', params: { uuid: this.uuid } });
        } else {
          this.form.pickup = !this.individualSelection;
          await this.api.create(this.form);
          this.$router.push({ name: 'order-list' });
        }

        this.$store.dispatch('notification/notifySuccess');
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError', 'No se pudieron guardar los datos.');
      }
      this.$store.commit('hideLoader');
    },

    async detail() {
      this.$store.commit('showLoader');
      try {
        const order = await this.api.detail(this.uuid);
        this.form = {
          number: order.number,
          items: this.getOrderItems(order),
          customer_id: order.customer.uuid,
          date: order.assignation.date,
          time_end: order.assignation.time_end,
          time_start: order.assignation.time_start,
          vehicle_id: order.assignation.vehicle?.uuid,
          employee_id: order.assignation.operator?.uuid,
        };
        this.office = order.office;
        this.individualSelection = !!order.products.length;
        this.readOnly = this.can('customer') && !order.paid;
        this.order = order;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getCustomers() {
      this.$store.commit('showLoader');
      try {
        const res = await this.customerAPI.list();
        this.customers = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getEmployees() {
      this.$store.commit('showLoader');
      try {
        const res = await this.employeeAPI.list({ form: true });
        this.employees = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getVehicles() {
      this.$store.commit('showLoader');
      try {
        const res = await this.vehicleAPI.list();
        this.vehicles = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getAvailableOffices() {
      this.$store.commit('showLoader');
      try {
        this.offices = await this.officeAPI.availableOffices({
          postal_code: this.postalCode,
        });
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    showPayment() {
      if (this.individualSelection) {
        this.total = this.$refs.productSelection.getTotal();
      } else {
        this.total = this.order.paid_pickup ? 0 : 35;
      }
      this.showPaymentModal = true;
    },
  },
};
</script>
