// API
import BaseAPI from './base';

/**
 * Implement Vehicle API
 */
export default class VehicleAPI extends BaseAPI {
  constructor() {
    super('vehicles');
  }

  /**
   * Get makes catalogue.
   */
  makes(params = {}) {
    return this.request.get(`${this.baseURI}/makes`, { params });
  }

  /**
   * Get models catalogue.
   */
  models(params = {}) {
    return this.request.get(`${this.baseURI}/models`, { params });
  }

  /**
   * Get types catalogue.
   */
  types(params = {}) {
    return this.request.get(`${this.baseURI}/types`, { params });
  }
}
