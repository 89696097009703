<template>
  <v-container class="text-center">
    <v-row>
      <v-col cols="12"> Agrega los servicios que necesites. </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-iterator :items="items" hide-default-footer>
          <template v-slot:no-data> No has seleccionado ningún servicio. </template>

          <template v-slot:header>
            <v-row justify="center">
              <v-col class="d-flex flex-column flex-md-row align-md-center" cols="12">
                <SelectField
                  v-model="serviceId"
                  :items="services"
                  :disabled="readOnly"
                  class="mr-md-5"
                  item-text="name"
                  item-value="id"
                  label="Familia"
                  prepend-icon="mdi-format-list-bulleted"
                  @change="getProducts"
                />

                <v-autocomplete
                  v-model="newProduct.product"
                  :items="products"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="uuid"
                  :disabled="readOnly || disableProductsCombo"
                  :placeholder="productsPlaceholder"
                  return-object
                />

                <v-btn
                  :disabled="readOnly"
                  class="mx-auto"
                  color="error"
                  fab
                  small
                  @click="addItem"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.product.uuid" cols="12" md="6">
                <v-card>
                  <v-card-subtitle>
                    <div class="d-flex justify-space-between">
                      <span>{{ item.product.name }}</span>

                      <span>
                        {{ item.product.unit_price_normal * item.quantity | currency }}
                      </span>
                    </div>

                    <v-divider class="my-3" />

                    <div class="d-flex justify-space-between">
                      <v-btn
                        :disabled="readOnly"
                        color="error"
                        icon
                        x-small
                        @click="removeItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>

                      <div>
                        <v-btn
                          :disabled="readOnly"
                          color="primary"
                          icon
                          x-small
                          @click="updateQuantity(item, -1)"
                        >
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <span class="mx-3">
                          {{ item.quantity }}
                        </span>

                        <v-btn
                          :disabled="readOnly"
                          color="primary"
                          icon
                          x-small
                          @click="updateQuantity(item, +1)"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-card class="mt-5">
              <v-card-text class="text-center">
                <b class="text-h5">TOTAL: {{ total | currency }}</b>
                <br />
                <small v-if="paidPickup" class="blue--text">
                  Se aplicó el descuento de $35 por la cita para recolección
                </small>

                <v-row v-if="can('customer')" class="mt-4" justify="center">
                  <v-col class="d-flex pa-0" cols="12" sm="6" lg="3">
                    <TextField
                      class="flex-grow-1"
                      maxlength="15"
                      label="Código de descuento"
                      prepend-icon="mdi-ticket"
                      rules=""
                      :value="discount_code"
                      @input="discount_code = $event.toUpperCase()"
                    />
                    <v-btn color="primary" @click="applyDiscount"> Aplicar </v-btn>
                  </v-col>

                  <v-col class="pa-0" cols="12">
                    <span :class="discount_alert.type">
                      {{ discount_alert.message }}
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// API
import DiscountAPI from '@/api/discount';
import OfficeAPI from '@/api/office';
import ProductAPI from '@/api/product';

const initialProduct = () => ({
  quantity: 1,
  product: null,
});

export default {
  name: 'CustomerForm',

  props: {
    items: {
      type: Array,
      required: true,
    },

    office: {
      type: Object,
      required: true,
    },

    paidPickup: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    discount_alert: {
      message: null,
      type: 'red--text',
    },
    discount_code: null,
    discount: null,
    newProduct: initialProduct(),
    products: [],
    services: [],
    serviceId: null,
  }),

  computed: {
    total() {
      let total = this.items.reduce(
        (sum, item) => sum + item.quantity * item.product.unit_price_normal,
        0,
      );

      if (this.paidPickup) {
        total -= 35;
      }

      if (this.discount) {
        const discountAmount = total * (this.discount.discount / 100);
        total -= discountAmount;
      }

      return total < 0 ? 0 : total;
    },

    disableProductsCombo() {
      return !this.products.length;
    },

    productsPlaceholder() {
      return this.disableProductsCombo ? 'No hay servicios' : 'Busca un servicio';
    },
  },

  watch: {
    office: 'getProducts',
  },

  async created() {
    this.api = new OfficeAPI();
    this.productAPI = new ProductAPI();
    this.discountAPI = new DiscountAPI();
    await this.getServices();
  },

  methods: {
    async getServices() {
      this.$store.commit('showLoader');
      try {
        const res = await this.productAPI.services();
        this.services = res.results;
      } catch (error) {
        console.log(error);
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    async getProducts() {
      const office = this.office.uuid || this.$store.getters.profile.office;

      if (!office) return;

      this.$store.commit('showLoader');
      try {
        this.products = await this.api.products(office, { service_id: this.serviceId });
      } catch (error) {
        this.$store.dispatch('notification/notifyError');
      }
      this.$store.commit('hideLoader');
    },

    addItem() {
      if (!this.newProduct.product) return;

      const foundItem = this.items.find((i) => i.product.uuid === this.newProduct.product.uuid);

      if (!foundItem) {
        this.items.push(this.newProduct);
      }

      this.newProduct = initialProduct();
    },

    removeItem(item) {
      const index = this.items.findIndex((i) => i === item);
      this.items.splice(index, 1);
    },

    updateQuantity(item, operation) {
      const selectedItem = this.items.find((i) => i === item);
      selectedItem.quantity += operation;

      if (selectedItem.quantity < 1) {
        selectedItem.quantity = 1;
      }
    },

    async applyDiscount() {
      this.$store.commit('showLoader');
      try {
        this.discount = null;
        this.discount = await this.discountAPI.getByCode({
          code: this.discount_code,
          office: this.office.uuid,
        });
        this.discount_alert = {
          message: `Descuento del ${this.discount.discount}% aplicado.`,
          type: 'green--text',
        };
      } catch (error) {
        // console.log(error);
        this.discount_alert = {
          message: error.data.message,
          type: 'red--text',
        };
      }
      this.$store.commit('hideLoader');
    },

    getTotal() {
      return this.total;
    },

    getDiscount() {
      return this.discount;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .quantity {
  input {
    text-align: center;
  }

  .v-label {
    left: calc(50% - 1.3em) !important;
  }
}
</style>
